// PageInsights.js
import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const PageInsights = () => {
  const insights = {
    desktop: { performance: 85, accessibility: 85, bestPractices: 96, seo: 83 },
    mobile: { performance: 70, accessibility: 78, bestPractices: 96, seo: 85 },
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Page Insights
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Desktop</Typography>
          <Typography variant="body1">
            Performance: {insights.desktop.performance}
          </Typography>
          <Typography variant="body1">
            Accessibility: {insights.desktop.accessibility}
          </Typography>
          <Typography variant="body1">
            Best Practices: {insights.desktop.bestPractices}
          </Typography>
          <Typography variant="body1">SEO: {insights.desktop.seo}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Mobile</Typography>
          <Typography variant="body1">
            Performance: {insights.mobile.performance}
          </Typography>
          <Typography variant="body1">
            Accessibility: {insights.mobile.accessibility}
          </Typography>
          <Typography variant="body1">
            Best Practices: {insights.mobile.bestPractices}
          </Typography>
          <Typography variant="body1">SEO: {insights.mobile.seo}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PageInsights;
