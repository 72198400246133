// TrafficType.js
import { Paper, Typography } from "@mui/material";
import React from "react";

const TrafficType = () => {
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Traffic Type
      </Typography>
      <Typography variant="body1">100% UK Traffic</Typography>
      <Typography variant="body1">100% Organic Traffic</Typography>
      <Typography variant="body1">
        53% Informational Traffic - Just coming to look
      </Typography>
      <Typography variant="body1">19% - 24% Transactional Traffic</Typography>
    </Paper>
  );
};

export default TrafficType;
