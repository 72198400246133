import {
  Box,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React from "react";
import AuthorityAndBacklinks from "./components/AuthorityAndBacklinks";
import MainCompetitors from "./components/MainCompetitors";
import PageInsights from "./components/PageInsights";
import TopSearches from "./components/TopSearches";
import TrafficTrend from "./components/TrafficTrend";
import TrafficType from "./components/TrafficType";

const theme = createTheme({
  palette: {
    background: {
      default: "#797D81", // This sets the default background color
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Normalize the default CSS */}
      <Container
        maxWidth="lg"
        sx={{ backgroundColor: "#797D81", minHeight: "100vh", padding: 3 }}
      >
        {/* Logo and Title */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "50%",
              maxWidth: 200,
              my: 2,
            }}
            alt="Logo"
            src="https://mindlabs.media/wp-content/uploads/2024/01/Asset-2@4x-1.png"
          />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{ color: "#fff", width: "100%" }}
          >
            SEO & Health Report - ThisCandy (6 Months)
          </Typography>
        </Box>

        {/* Components Grid */}
        <Grid container spacing={2}>
          {/* Full width components */}
          <Grid item xs={12}>
            <PageInsights />
          </Grid>
          <Grid item xs={12}>
            <AuthorityAndBacklinks />
          </Grid>

          {/* Half width components */}
          <Grid item xs={12} md={6}>
            <TopSearches />
          </Grid>
          <Grid item xs={12} md={6}>
            <TrafficType />
          </Grid>

          {/* Traffic Trend - Full width */}
          <Grid item xs={12}>
            <TrafficTrend />
          </Grid>

          {/* Main Competitors - Full width */}
          <Grid item xs={12}>
            <MainCompetitors />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default App;
