// TopSearches.js
import { Paper, Typography } from "@mui/material";
import React from "react";

const TopSearches = () => {
  const searches = [
    "this candy",
    "candy delivery",
    "fizzy strawberry belts",
    "fizzy belts",
  ];

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Top Searches to Receive Your Site
      </Typography>
      {searches.map((search, index) => (
        <Typography key={index} variant="body1">
          {search}
        </Typography>
      ))}
    </Paper>
  );
};

export default TopSearches;
