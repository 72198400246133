// AuthorityAndBacklinks.js
import { Paper, Typography } from "@mui/material";
import React from "react";

const AuthorityAndBacklinks = () => {
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Last 6 Months Report
      </Typography>
      <Typography variant="body1">Authority Score: 9</Typography>
      <Typography variant="body1">Organic Traffic Score: 133</Typography>
      <Typography variant="body1">Backlinks: 111</Typography>
    </Paper>
  );
};

export default AuthorityAndBacklinks;
