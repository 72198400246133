// MainCompetitors.js
import { Link, Paper, Typography } from "@mui/material";
import React from "react";

const MainCompetitors = () => {
  return (
    <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Main Competitors
      </Typography>
      <Link href="https://picknmixes.co.uk/" target="_blank" rel="noopener">
        picknmixes.co.uk
      </Link>
      <br />
      <Link href="https://candybreak.com/" target="_blank" rel="noopener">
        candybreak.com
      </Link>
    </Paper>
  );
};

export default MainCompetitors;
